import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    width: rem(340),
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.neutral[800]} `,
    borderRadius: rem(16),
    padding: rem(24),
  },
  review: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  stars: {
    color: palette.supporting.amber[500],
    marginBottom: rem(16),
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
    marginTop: rem(24),
  },
}));
