import { defineMessages } from 'react-intl';

export default defineMessages({
  verified: {
    id: 'landingMozza.certifiedTeachers.teacherCard.verified',
    defaultMessage: 'Sherpas vérifié',
  },
  feedbackSignature: {
    id: 'landingMozza.certifiedTeachers.teacherCard.feedbackSignature',
    defaultMessage: 'Par {name}',
  },
});
