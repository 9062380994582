import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    display: 'block',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    borderRadius: rem(8),
  },
  withBorder: {
    border: `1px solid ${palette.supporting.blue[900]}`,
  },
}));
