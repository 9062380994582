import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import CheckIcon from '@/Atoms/Icons/CheckIcon';
import DashIcon from '@/Atoms/Icons/DashIcon';
import Cell from '@/components/LandingMozza/common/PricingSection/PricingTable/Cell/Cell';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import themeFoundation from '@/designSystem/theme';

import messages from './messages';

type Props = {
  column: 'advantage' | 'agency' | 'sherpas';
  rowNumber: number;
};

const ContentCellDesktop: FC<Props> = ({ column, rowNumber }) => (
  <Cell withBackground={column === 'sherpas'} isCenter={column !== 'advantage'}>
    <Typography variant="bodyLg">
      <FormattedMessage
        {...messages[`${column}${rowNumber}`]}
        values={{
          sup: (chunks) => <sup>{chunks}</sup>,
          dashIcon: () => <DashIcon />,
          checkIcon: () => <CheckIcon />,
          enter: EnterChunk,
          primary: (chunks) => (
            <Typography
              variant="bodyLgBold"
              component="span"
              color={themeFoundation.palette.neutral[200]}
            >
              {chunks}
            </Typography>
          ),
          secondary: (chunks) => (
            <Typography
              variant="bodyLgBold"
              component="span"
              color={themeFoundation.palette.neutral[500]}
            >
              {chunks}
            </Typography>
          ),
        }}
      />
    </Typography>
  </Cell>
);

export default ContentCellDesktop;
