import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(60),
    [breakpoints.up('md')]: {
      padding: `0 ${rem(54)}`,
    },
  },
  title: {
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(12),
    [breakpoints.up('md')]: {
      gap: rem(24),
    },
  },
}));
