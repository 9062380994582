import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import CheckIcon from '@/Atoms/Icons/CheckIcon';
import DashIcon from '@/Atoms/Icons/DashIcon';
import Cell from '@/components/LandingMozza/common/PricingSection/PricingTable/Cell/Cell';
import { DisplayedColumn } from '@/components/LandingMozza/common/PricingSection/types';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import themeFoundation from '@/designSystem/theme';

import messages from './messages';

import useStyles from './styles';

type Props = {
  displayedColumn: DisplayedColumn;
  rowNumber: number;
  isLastRow?: boolean;
};

const ContentCellMobile: FC<Props> = ({
  displayedColumn,
  rowNumber,
  isLastRow = false,
}) => {
  const { classes } = useStyles();

  return (
    <Cell
      withBackground={displayedColumn === 'sherpas'}
      className={classes.mobileCell}
      isLastRow={isLastRow}
    >
      <Typography variant="bodyMd">
        <FormattedMessage
          {...messages[`advantage${rowNumber}`]}
          values={{
            primary: (chunks) => (
              <Typography
                variant="bodyMdBold"
                component="span"
                color={themeFoundation.palette.neutral[200]}
              >
                {chunks}
              </Typography>
            ),
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
        />
      </Typography>
      <Typography
        variant="bodyMd"
        color={themeFoundation.palette.neutral[500]}
        className={classes.rightSection}
      >
        <FormattedMessage
          {...messages[`${displayedColumn}${rowNumber}`]}
          values={{
            sup: (chunks) => <sup>{chunks}</sup>,
            dashIcon: () => <DashIcon />,
            checkIcon: () => <CheckIcon />,
            enter: EnterChunk,
            primary: (chunks) => (
              <Typography
                variant="bodyMdBold"
                component="span"
                color={themeFoundation.palette.neutral[200]}
              >
                {chunks}
              </Typography>
            ),
            secondary: (chunks) => (
              <Typography
                variant="bodyMdBold"
                component="span"
                color={themeFoundation.palette.neutral[500]}
              >
                {chunks}
              </Typography>
            ),
          }}
        />
      </Typography>
    </Cell>
  );
};

export default ContentCellMobile;
