import { FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import { Typography } from '@mui/material';

import themeFoundation from '@/designSystem/theme';
import { TagProvider } from '@/Molecules/Tag/Tag.context';
import { TagCloseIconProps, TagProps } from '@/Molecules/Tag/Tag.types';
import TagCloseIcon from '@/Molecules/Tag/TagCloseIcon/TagCloseIcon';

import useStyles from './styles';

type Compound = {
  CloseIcon: FC<TagCloseIconProps>;
};

const Tag: FC<PropsWithChildren<TagProps & HTMLAttributes<HTMLDivElement>>> &
  Compound = ({
  label,
  backgroundColor = themeFoundation.palette.neutral[900],
  color = themeFoundation.palette.neutral[200],
  Icon = '',
  className,
  children,
  typographyVariant = 'bodySmBold',
  icon,
  isRounded,
  size = 'medium',
  ...rest
}) => {
  const { classes, cx } = useStyles({
    backgroundColor,
    color,
    isRounded,
    size,
  });
  const context = useMemo(() => ({ size, color }), [size, color]);
  return (
    <TagProvider value={context}>
      <div className={cx(classes.root, className)} {...rest}>
        {Icon && <Icon className={classes.icon} />}
        {!!icon && icon}
        <Typography
          component="span"
          variant={typographyVariant}
          className={classes.typography}
        >
          {label}
        </Typography>
        {children}
      </div>
    </TagProvider>
  );
};

Tag.CloseIcon = TagCloseIcon;

export default Tag;
