import { FC, HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';
import { useIsMobile } from '@/utils/useResponsive';

import logos from './config';
import messages from './messages';

import useStyles from './styles';

const Logos: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  const { classes, cx } = useStyles();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();
  return (
    <section className={cx(classes.logos, className)}>
      {logos.map(({ url, altIntlId }) => (
        <Image
          alt={formatMessage(messages[altIntlId])}
          src={url}
          width={isMobile ? 160 : 120}
          height={isMobile ? 40 : 36}
          loader={getImageFromCloudImage}
          key={altIntlId}
          className={classes.logoImg}
        />
      ))}
    </section>
  );
};

export default Logos;
