import { defineMessages } from 'react-intl';

export default defineMessages({
  firstStar: {
    id: 'landing.mozza.pricing.section.first.star',
    defaultMessage:
      '*Chiffres et pourcentages moyens observés dans les agences classiques de soutien scolaire en septembre 2024.',
  },
  secondStar: {
    id: 'landing.mozza.pricing.section.second.star',
    defaultMessage:
      "**Oui, vous avez bien lu. Nous non plus, nous n'y avons pas cru au début ! 😲 Prix moyen payé par une famille pour une heure de cours dans une agence classique 40 €/h, Revenu brut touché par le professeur 13,5 €/h. Commission en pourcentage = (Prix payé par la famille - Revenu du professeur)/(Revenu du professeur) * 100. Soit : (40 - 13,5) / 13,5 * 100 ≈ 200 %",
  },
});
