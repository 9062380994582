import { defineMessages } from 'react-intl';

export default defineMessages({
  centrale: {
    id: 'landingMozza.certifiedTeachers.logos.centrale',
    defaultMessage: `CentraleSupélec`,
  },
  parisDauphine: {
    id: 'landingMozza.certifiedTeachers.logos.parisDauphine',
    defaultMessage: `Université Paris-Dauphine-PSL`,
  },
  ens: {
    id: 'landingMozza.certifiedTeachers.logos.ens',
    defaultMessage: `ENS (École normale supérieure)`,
  },
  essec: {
    id: 'landingMozza.certifiedTeachers.logos.essec',
    defaultMessage: `ESSEC (École Supérieure des Sciences Économiques et Commerciales)`,
  },
  hec: {
    id: 'landingMozza.certifiedTeachers.logos.hec',
    defaultMessage: `HEC (École des hautes études commerciales de Paris)`,
  },
  mines: {
    id: 'landingMozza.certifiedTeachers.logos.mines',
    defaultMessage: `École des Mines de Paris`,
  },
  sciencesPo: {
    id: 'landingMozza.certifiedTeachers.logos.sciencesPo',
    defaultMessage: `Sciences Po (Institut d'études politiques de Paris)`,
  },
  ecolePolytechnique: {
    id: 'landingMozza.certifiedTeachers.logos.ecolePolytechnique',
    defaultMessage: `École polytechnique`,
  },
});
