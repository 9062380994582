import { FC } from 'react';

const DashIcon: FC = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    style={{ flexShrink: 0 }}
  >
    <path
      d="M5.70834 13H20.2917"
      stroke="#D4D4D8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DashIcon;
