import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  mobileCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  rightSection: {
    textAlign: 'right',
  },
}));
