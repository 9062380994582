import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import CheckWithCircle from '@/Atoms/Icons/CheckWithCircle';
import { aboutTeachers } from '@/components/LandingMozza/common/CertifiedTeachers/AboutTeacher/messages';

import useStyles from './styles';

type Props = {
  isMobile: boolean;
};

const AboutTeacher: FC<Props> = ({ isMobile }) => {
  const { classes } = useStyles();

  return (
    <ul className={classes.container}>
      {Object.keys(aboutTeachers).map((key) => (
        <li className={classes.row} key={key}>
          <CheckWithCircle />
          <Typography
            variant={isMobile ? 'bodyMd' : 'bodyLg'}
            component="span"
            className={classes.label}
          >
            <FormattedMessage {...aboutTeachers[key]} />
          </Typography>
        </li>
      ))}
    </ul>
  );
};

export default AboutTeacher;
