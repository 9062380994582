import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: rem(60),
    overflow: 'hidden',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: rem(245),
  },
}));
