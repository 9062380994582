import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { CSS_ELEMENT_TOP } from '@/hooks/animation/useAddContainerTopValueOnCssVar';

const animationSpeed = 0.2;

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    '--animation-speed': 0.2,
    width: '100%',
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridAutoFlow: 'column',
    overflow: 'hidden',
    gridGap: rem(24),
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '30%',
      background: `linear-gradient(to right, ${palette.common.white}, transparent 100%)`,
      zIndex: 1,
    },
    '&:after': {
      content: '""',
      marginRight: rem(-10),
      position: 'absolute',
      background: `linear-gradient(to left, ${palette.common.white} , transparent 100%)`,
      width: '30%',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
    '&>:nth-child(2n)': {
      transform: `translateX(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed}))`,
    },
    '&>:nth-child(2n+1)': {
      transform: `translateX(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed} * -1))`,
    },
    '& > :nth-child(2n+2)': {
      marginLeft: rem(-190),
    },

    [breakpoints.up('lg')]: {
      '& > :nth-child(2n+2)': {
        marginLeft: rem(-130),
      },
      '&: before, &: after': {
        width: '15%',
      },
    },
  },
}));
