import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    display: 'table-cell',
    padding: rem(24),
    width: '1%',
    background: palette.common.white,
  },
  borderBottom: {
    borderBottom: `1px solid ${palette.supporting.blue[900]}`,
  },
  isCenter: {
    textAlign: 'center',
  },
  isHeader: {
    height: rem(100),
    borderTopLeftRadius: rem(10),
    borderTopRightRadius: rem(10),
  },
  isFooter: {
    height: rem(100),
    border: 'none',
    borderBottomLeftRadius: rem(10),
    borderBottomRightRadius: rem(10),
  },
  withBackground: {
    background: palette.supporting.sky.light,
  },
}));
