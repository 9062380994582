import { defineMessages } from 'react-intl';

export default defineMessages({
  button: {
    id: 'landing.mozza.methodology.button',
    defaultMessage: 'Découvrir nos professeurs',
  },
  etienneImageAlt: {
    id: 'landing.mozza.methodology.etienneImageAlt',
    defaultMessage: 'Cours de méthodologie animé par Etienne des Sherpas',
  },
});
