import { LogoType } from '@/components/LandingMozza/common/CertifiedTeachers/Logos/types';

const config: LogoType[] = [
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/Centrale+(1).png',
    altIntlId: 'centrale',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/Dauphine.png',
    altIntlId: 'parisDauphine',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/ENS.png',
    altIntlId: 'ens',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/Essec.png',
    altIntlId: 'essec',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/HEC.png',
    altIntlId: 'hec',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/Mines.png',
    altIntlId: 'mines',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/SciencesPo.png',
    altIntlId: 'sciencesPo',
  },
  {
    url: 'https://sherpas-uploads.s3.eu-west-3.amazonaws.com/Landing/X.png',
    altIntlId: 'ecolePolytechnique',
  },
];

export default config;
