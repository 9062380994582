import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import TypographyUnderlined from '@/components/LandingMozza/LandingMozza/common/TypographyUnderlined/TypographyUnderlined';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';

import messages from './messages';

const Header: FC = () => (
  <ArticleHeader>
    <SectionTitle>
      <FormattedMessage
        {...messages.title}
        values={{
          sup: (chunks) => <sup>{chunks}</sup>,
          underlined: (chunks) => (
            <TypographyUnderlined>
              <span>{chunks}</span>
            </TypographyUnderlined>
          ),
        }}
      />
    </SectionTitle>
    <Typography component="p" variant="bodyXl">
      <FormattedMessage
        {...messages.subtitle}
        values={{
          enter: EnterChunk,
        }}
      />
    </Typography>
  </ArticleHeader>
);

export default Header;
