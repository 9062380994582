import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import Cell from '@/components/LandingMozza/common/PricingSection/PricingTable/Cell/Cell';
import { DisplayedColumn } from '@/components/LandingMozza/common/PricingSection/types';
import SherpasLogo from '@/designSystem/Sherpas/SherpasLogo';

import messages from './messages';

import useStyles from './styles';

type Props = {
  displayedColumn: DisplayedColumn;
};

const HeaderCell: FC<Props> = ({ displayedColumn }) => {
  const { classes } = useStyles();

  return (
    <Cell withBackground={displayedColumn === 'sherpas'} isHeader isCenter>
      <Typography component="p" className={classes.text}>
        <FormattedMessage
          {...messages[displayedColumn]}
          values={{
            secondLine: (chunks) => (
              <span className={classes.secondLine}>{chunks}</span>
            ),
            logo: () => <SherpasLogo />,
          }}
        />
      </Typography>
    </Cell>
  );
};

export default HeaderCell;
