import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.mozza.reviews.section.title',
    defaultMessage:
      'Des milliers de parents et d’élèves sont<enter></enter>satisfaits de leur accompagnement',
  },
  button: {
    id: 'landing.mozza.reviews.section.button',
    defaultMessage: 'Découvrir nos professeurs',
  },
});
