import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { CSS_ELEMENT_TOP } from '@/hooks/animation/useAddContainerTopValueOnCssVar';

const animationSpeed = 0.3;

export default makeStyles()(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(40),
  },
  title: {
    fontWeight: 400,
  },

  reviewContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: rem(16),
    overflowX: 'scroll',
    zIndex: 1,
    width: `100%`,
    '&::-webkit-scrollbar': {
      display: 'none',
    },

    [breakpoints.up('md')]: {
      position: 'absolute',
      top: rem(-284),
      left: rem(20),
      margin: `0 auto`,
      overflowX: 'unset',
      overflow: 'hidden',
      display: 'grid',
      width: rem(644),
      gap: `${rem(24)} ${rem(8)}`,
      gridTemplateColumns: '1fr 1fr',
      transform: `rotate(5deg)`,
      '&>:nth-child(2n)': {
        transform: `translateY(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed}))`,
      },
      '&>:nth-child(2n+1)': {
        transform: `translateY(calc(var(${CSS_ELEMENT_TOP}) * ${animationSpeed} * -1))`,
      },
    },
  },
  reviews: {
    width: '100%',
    height: rem(248),
    overflow: 'hidden',
    marginTop: rem(60),
    [breakpoints.up('md')]: {
      marginTop: 0,
      width: '56%',
      position: 'relative',
      height: rem(674),
      '&:before': {
        content: '""',
        position: 'absolute',
        background:
          'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);',
        height: rem(100),
        width: '100%',
        top: 0,
        zIndex: 9,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);',
        height: rem(100),
        width: '100%',
        bottom: 0,
        zIndex: 9,
      },
    },
  },
  reviewItem: {
    margin: '0 auto',
  },
  logos: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
