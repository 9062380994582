import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => ({
  root: {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
  },
  starIcon: {
    color: theme.palette.supporting.amber[500],
    width: '16px',
    height: '16px',
    margin: `0 ${rem(6)} 0 ${rem(4)}`,
  },
  starValue: {
    marginRight: rem(8),
    fontWeight: 'bold',
    fontSize: rem(18),
    color: 'inherit',
  },
}));
