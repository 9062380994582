import { FC, HTMLAttributes } from 'react';

import { IconProps } from '@/Atoms/Icons/Icons.type';
import themeFoundation from '@/designSystem/theme';

const IconCertified: FC<IconProps & HTMLAttributes<HTMLDivElement>> = ({
  size = 32,
  color = themeFoundation.palette.supporting.blue[300],
}) => (
  <svg
    width={size}
    height={size}
    style={{ flexShrink: 0 }}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.53403 2.90991C9.82016 2.80728 11.0411 2.30153 12.0232 1.46467C14.3148 -0.488224 17.6852 -0.488224 19.9768 1.46467C20.9589 2.30153 22.1798 2.80728 23.466 2.90991C26.4673 3.14942 28.8506 5.53271 29.0901 8.53403C29.1927 9.82016 29.6985 11.0411 30.5353 12.0232C32.4882 14.3148 32.4882 17.6852 30.5353 19.9768C29.6985 20.9589 29.1927 22.1798 29.0901 23.466C28.8506 26.4673 26.4673 28.8506 23.466 29.0901C22.1798 29.1927 20.9589 29.6985 19.9768 30.5353C17.6852 32.4882 14.3148 32.4882 12.0232 30.5353C11.0411 29.6985 9.82016 29.1927 8.53403 29.0901C5.53271 28.8506 3.14942 26.4673 2.90991 23.466C2.80728 22.1798 2.30153 20.9589 1.46467 19.9768C-0.488224 17.6852 -0.488224 14.3148 1.46467 12.0232C2.30153 11.0411 2.80728 9.82016 2.90991 8.53403C3.14942 5.53271 5.53271 3.14942 8.53403 2.90991ZM23.4142 13.4142C24.1953 12.6332 24.1953 11.3668 23.4142 10.5858C22.6332 9.80474 21.3668 9.80474 20.5858 10.5858L14 17.1716L11.4142 14.5858C10.6332 13.8047 9.36683 13.8047 8.58579 14.5858C7.80474 15.3668 7.80474 16.6332 8.58579 17.4142L12.5858 21.4142C13.3668 22.1953 14.6332 22.1953 15.4142 21.4142L23.4142 13.4142Z"
      fill={color}
    />
  </svg>
);

export default IconCertified;
