import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(60),
  },
  borderRadius: {
    borderRadius: rem(16),
    overflow: 'hidden',
  },
  tableContainer: {
    background: palette.common.white,
    minWidth: 'fit-content',
    padding: `${rem(16)}`,
    [breakpoints.up('md')]: {
      padding: `${rem(16)} ${rem(16)} ${rem(16)} 0`,
    },
  },
  title: {
    textAlign: 'center',
  },

  lightGrey: {
    color: palette.neutral[500],
  },
}));
