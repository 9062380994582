import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import Cotillon from '@/components/LandingMozza/common/Cotillon/Cotillon';
import Legend from '@/components/LandingMozza/common/PricingSection/Legend/Legend';
import PricingSectionCotillonSvg from '@/components/LandingMozza/common/PricingSection/PricingSectionCotillonSvg';
import PricingTable from '@/components/LandingMozza/common/PricingSection/PricingTable/PricingTable';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import TypographyUnderlined from '@/components/LandingMozza/LandingMozza/common/TypographyUnderlined/TypographyUnderlined';
import Enter from '@/generic/Enter/Enter';

import messages from './messages';

import useStyles from './styles';

type Props = {
  isMobile: boolean;
  handleRedirection: () => void;
};

const PricingSection: FC<Props> = ({ isMobile, handleRedirection }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Cotillon top={272} right={-70}>
        <PricingSectionCotillonSvg />
      </Cotillon>
      <ArticleHeader>
        <SectionTitle className={classes.title}>
          <FormattedMessage
            {...messages.title}
            values={{
              enter: (chunk) => <Enter>{chunk}</Enter>,
              underlined: (chunks) => (
                <TypographyUnderlined>
                  <span>{chunks}</span>
                </TypographyUnderlined>
              ),
            }}
          />
        </SectionTitle>
      </ArticleHeader>
      <div className={classes.content}>
        {isMobile ? (
          <>
            <PricingTable
              displayedColumn="agency"
              handleRedirection={handleRedirection}
            />
            <PricingTable
              displayedColumn="sherpas"
              handleRedirection={handleRedirection}
            />
          </>
        ) : (
          <PricingTable
            displayedColumn="both"
            isMobile={false}
            handleRedirection={handleRedirection}
          />
        )}
        <Legend />
      </div>
    </div>
  );
};

export default PricingSection;
