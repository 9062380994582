import * as React from 'react';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatRating } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import IconVerified from '@/Atoms/Icons/IconCertified';
import Avatar from '@/Molecules/Avatar/Avatar';
import Tag from '@/Molecules/Tag/Tag';

import StarRating from './StarRating/StarRating';
import messages from './messages';

import useStyles from './styles';

type Props = {
  teacherPictureUrl?: string;
  teacherFirstName: string;
  teacherLastName: string;
  studentFirstName: string;
  subjectName: string;
  rating: number;
  feedback: string;
  className?: string;
};

const TeacherCard: FC<Props> = ({
  teacherFirstName,
  teacherLastName,
  teacherPictureUrl,
  studentFirstName,
  feedback,
  subjectName,
  rating,
  className,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.headerAndFeedback}>
        <div className={classes.cardHeader}>
          <Avatar
            firstName={teacherFirstName}
            lastName={teacherLastName}
            width={68}
            height={68}
            photoUrl={teacherPictureUrl}
            alt={`${teacherFirstName} donne son avis sur les cours particuliers des Sherpas`}
          />
          <div className={classes.rightContainer}>
            <div className={classes.name}>
              <Typography variant="title2Xs" component="span">
                {teacherFirstName}
              </Typography>
              <IconVerified size={16} />
            </div>
            <div className={classes.flexContainer}>
              <Tag label={subjectName} />
              <StarRating>{formatRating(rating)}</StarRating>
            </div>
          </div>
        </div>
        <Typography className={classes.noWrap}>{feedback}</Typography>
      </div>
      <Typography className={classes.studentName}>
        <FormattedMessage
          {...messages.feedbackSignature}
          values={{ name: studentFirstName }}
        />
      </Typography>
    </div>
  );
};

export default TeacherCard;
