import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'landingMozza.certifiedTeachers.title',
    defaultMessage: `Nos professeurs particuliers<enter></enter><underlined>sont certifiés</underlined> par notre équipe`,
  },
  btnLabel: {
    id: 'landingMozza.certifiedTeachers.btnLabel',
    defaultMessage: `Découvrir nos professeurs`,
  },
});

export const subjects = defineMessages({
  spanish: {
    id: 'landingMozza.certifiedTeachers.subject.spanish',
    defaultMessage: 'Espagnol',
  },
  maths: {
    id: 'landingMozza.certifiedTeachers.subject.maths',
    defaultMessage: 'Maths',
  },
  english: {
    id: 'landingMozza.certifiedTeachers.subject.english',
    defaultMessage: 'Anglais',
  },
  french: {
    id: 'landingMozza.certifiedTeachers.subject.french',
    defaultMessage: 'Français',
  },
  algebra: {
    id: 'landingMozza.certifiedTeachers.subject.algebra',
    defaultMessage: 'Algèbre',
  },
  geopolitics: {
    id: 'landingMozza.certifiedTeachers.subject.geopolitics',
    defaultMessage: 'Géopolitique',
  },
});

export const feedbacks = defineMessages({
  evaSpanishCharline: {
    id: 'landingMozza.certifiedTeachers.feedback.evaSpanishCharline',
    defaultMessage: `Super cours ! Je pense que la méthode proposée et la reprise des précédents DS en analysant les erreurs récurrentes vont vraiment m'aider à améliorer mon espagnol`,
  },
  amineMathsYoann: {
    id: 'landingMozza.certifiedTeachers.feedback.amineMathsYoann',
    defaultMessage: `Un enseignant très diligent et à l’écoute, en plus d’être très efficace`,
  },
  catherineEnglishElina: {
    id: 'landingMozza.certifiedTeachers.feedback.catherineEnglishElina',
    defaultMessage: `Catherine m'a suivi durant ma préparation pour passer le Toeic, et je ne peux que la remercier. Elle m'a entraîné sur chaque point faible que j'avais ! Les cours étaient fait selon mes besoins et les difficultés que je rencontrais ! Je la recommande grandement !`,
  },
  jeanMathsCatherine: {
    id: 'landingMozza.certifiedTeachers.feedback.jeanMathsCatherine',
    defaultMessage: `Jean est à l’écoute des objectifs définis par l’élève. Beaucoup de bienveillance. Des conseils methodo et une maitrise de la pédagogie qui aident à progresser! Merci !`,
  },
  lenaFrenchAntonina: {
    id: 'landingMozza.certifiedTeachers.feedback.lenaFrenchAntonina',
    defaultMessage: `Léna est rassurante et très à l’écoute, ses cours et ses explications sont toujours clairs ! j’ai adoré travailler avec elle`,
  },
  douniaMathsLeonie: {
    id: 'landingMozza.certifiedTeachers.feedback.douniaMathsLeonie',
    defaultMessage: `Dounia explique bien. Elle donne des moyens memo techniques pour facilité l'apprentissage. Elle met a l'aise et est disponible`,
  },
  philippineSpanishAntoineMarie: {
    id: 'landingMozza.certifiedTeachers.feedback.philippineSpanishAntoineMarie',
    defaultMessage: `Excellent professeur d'Espagnol : Philippine prend bien le temps pour expliquer les choses. Sa méthode est efficace car elle nous fait trouver nous même nos erreurs, elle fait travailler la prononciation et l'orthographe des mots en échangeant avec nous et en se servant du chat. Les exercices et listes de vocabulaire qu'elle envoie sont très instructifs et personnalisés. Je recommande vivement!!`,
  },
  jeanMathsLucas: {
    id: 'landingMozza.certifiedTeachers.feedback.jeanMathsLucas',
    defaultMessage: `Professeur très agréable, très serviable, je recommande vivement !!`,
  },
  clementGeopoliticsPierre: {
    id: 'landingMozza.certifiedTeachers.feedback.clementGeopoliticsPierre',
    defaultMessage: `Les cours avec Clément m'ont énormément fait progresser. Son approche, centrée sur la méthodologie, est très efficace. Par ailleurs, il est à la fois flexible sur les horaires et très impliqué avec ses élèves.`,
  },
  raphaelFrenchDelphine: {
    id: 'landingMozza.certifiedTeachers.feedback.raphaelFrenchDelphine',
    defaultMessage: `Un cours hautement efficace, preparé en amont par Raphaël, révélateur du niveau d'expérience certain dans l'enseignement de la matière délivré par ce dernier. Le cadre est donné, la nécessité d'un travail conséquent à fournir en parallèle est bien comprise par notre fils, qui est prêt à faire une dissertation par semaine. Et, chose inattendue, s'est retroussé les manches dès 5h30 le lendemain matin pour se pencher sur ses notions de philo!`,
  },
});
