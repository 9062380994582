import { FC } from 'react';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import CloseButton from '@/Molecules/CloseButton/CloseButton';
import { useTagContext } from '@/Molecules/Tag/Tag.context';
import {
  TagCloseIconProps,
  TagCloseIconStyle,
} from '@/Molecules/Tag/Tag.types';

const useStyles = makeStyles<TagCloseIconStyle>()((_, { color, size }) => ({
  tagCloseIcon: {
    marginLeft: 0,
    color,
  },
  icon: {
    height: size === 'small' ? rem(12) : rem(16),
    width: size === 'small' ? rem(12) : rem(16),
  },
}));

const TagCloseIcon: FC<TagCloseIconProps> = ({ onClick }) => {
  const { size, color } = useTagContext();
  const { classes } = useStyles({ size, color });
  return (
    <CloseButton
      onClick={onClick}
      className={classes.tagCloseIcon}
      classIcon={classes.icon}
    />
  );
};

export default TagCloseIcon;
