import { FC } from 'react';

import Card from '@/components/LandingMozza/common/CustomerReviews/Card/Card';
import { CustomerReview } from '@/components/LandingMozza/common/CustomerReviews/types';
import useAddContainerTopValueOnCssVar from '@/hooks/animation/useAddContainerTopValueOnCssVar';

import useStyles from './styles';

type Props = {
  reviews: CustomerReview[];
};

const CustomerReviews: FC<Props> = ({ reviews = [] }) => {
  const { classes } = useStyles();
  const { ref } = useAddContainerTopValueOnCssVar({
    elementMargins: { top: 20, bottom: 20 },
  });

  return (
    <div className={classes.container} ref={ref}>
      {reviews.map((review) => (
        <Card key={review.id} review={review} />
      ))}
    </div>
  );
};

export default CustomerReviews;
