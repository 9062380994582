import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

import useStyles from './styles';

const StarRating: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...rest}>
      <StarIcon className={classes.starIcon} />
      <Typography component="span" className={classes.starValue}>
        {children}
      </Typography>
    </div>
  );
};

export default StarRating;
