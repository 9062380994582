import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  text: {
    fontWeight: 500,
    fontSize: rem(12),
    [breakpoints.up('md')]: {
      fontSize: rem(20),
    },
  },
  secondLine: {
    display: 'block',
    margin: rem(14),
  },
}));
