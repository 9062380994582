import { FC } from 'react';
import { useIntl } from 'react-intl';
import Image from 'next/image';

import Cotillon from '@/components/LandingMozza/common/Cotillon/Cotillon';
import Header from '@/components/LandingMozza/common/Methodology/Header/Header';
import MethodologyCotillonSvg from '@/components/LandingMozza/common/Methodology/MethodologyCotillonSvg';
import Row from '@/components/LandingMozza/common/Methodology/Row/Row';
import Button from '@/designSystem/Button/Button';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

type Props = {
  handleRedirection: () => void;
};

const Methodology: FC<Props> = ({ handleRedirection }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <Cotillon top={14} right={-70}>
        <MethodologyCotillonSvg />
      </Cotillon>
      <Header />
      <div className={classes.content}>
        <ul className={classes.rows}>
          {[
            'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/newspaper.png',
            'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/microphone.png',
            'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/phone.png',
          ].map((imageSrc, index) => (
            <Row
              key={`methodology_${index}`}
              imageSrc={imageSrc}
              rowIndex={index + 1}
            />
          ))}
        </ul>

        <Image
          alt={formatMessage(messages.etienneImageAlt)}
          width={543}
          height={504}
          loader={getImageFromCloudImage}
          quality={100}
          src="https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/EtienneLanding.png"
          className={classes.image}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="primary"
          size="large"
          onClick={handleRedirection}
          label={formatMessage(messages.button)}
        />
      </div>
    </div>
  );
};

export default Methodology;
