import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  logos: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: rem(40),
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  logoImg: {
    objectFit: 'contain',
  },
}));
