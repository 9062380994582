import { defineMessages } from 'react-intl';

export default defineMessages({
  methodology1: {
    id: 'landing.mozza.methodology.newspaper',
    defaultMessage:
      '<strong>Accédez gratuitement au contenu de nos médias </strong> (notre blog, qui compte un million de lecteurs par mois et notre chaîne Youtube, suivie par près de 150 000 abonnés)',
  },
  methodology2: {
    id: 'landing.mozza.methodology.microphone',
    defaultMessage:
      '<strong>Suivez nos conférences et nos masterclass</strong> mensuelles sur l’apprentissage et ses techniques',
  },
  methodology3: {
    id: 'landing.mozza.methodology.phone',
    defaultMessage:
      '<strong>Échangez avec notre équipe pédagogique</strong> afin d’améliorer votre méthodologie de travail',
  },
  altImage1: {
    id: 'landing.mozza.methodology.altImage1',
    defaultMessage: 'Contenu des médias Sherpas',
  },
  altImage2: {
    id: 'landing.mozza.methodology.altImage2',
    defaultMessage: 'Conférences et masterclass Sherpas',
  },
  altImage3: {
    id: 'landing.mozza.methodology.altImage3',
    defaultMessage: 'L’équipe pédagogique Sherpas',
  },
});
