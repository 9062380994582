import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

type Props = {
  imageSrc: string;
  rowIndex: number;
};

const Row: FC<Props> = ({ rowIndex, imageSrc }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <li className={classes.container}>
      <Image
        src={imageSrc}
        alt={formatMessage(messages[`altImage${rowIndex}`])}
        width={75}
        height={75}
        loader={getImageFromCloudImage}
        quality={100}
      />
      <Typography variant="bodyLg" className={classes.text}>
        <FormattedMessage
          {...messages[`methodology${rowIndex}`]}
          values={{
            strong: (chunks) => (
              <Typography variant="bodyLgBold" component="span">
                {chunks}
              </Typography>
            ),
          }}
        />
      </Typography>
    </li>
  );
};

export default Row;
