import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.mozza.methodology.title',
    defaultMessage:
      '(Ré)apprenez à apprendre avec <underlined>la Méthode Sherpas</underlined> <sup>®</sup>',
  },
  subtitle: {
    id: 'landing.mozza.methodology.subtitle',
    defaultMessage:
      'Comment travailler efficacement et être bien dans ses baskets ? Comment retenir ? Faut-il faire des fiches ?<enter></enter>Découvrez nos hacks et astuces de productivité avec notre méthode d’apprentissage basée sur dix ans de recherche.',
  },
});
