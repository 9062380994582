import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(60),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(80),
  },
  rows: {
    margin: rem(0),
    padding: rem(0),
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: rem(40),
  },
  image: {
    display: 'none',
    [breakpoints.up('lg')]: {
      display: 'block',
      maxWidth: '40%',
      height: 'auto',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
