import { FC } from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Typography } from '@mui/material';

import { CustomerReview } from '@/components/LandingMozza/common/CustomerReviews/types';
import Avatar from '@/Molecules/Avatar/Avatar';

import useStyles from './styles';

type Props = {
  review: CustomerReview;
};

const Card: FC<Props> = ({ review }) => {
  const { firstName, lastName, comment } = review;
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {new Array(5).fill(' ').map((_, index) => (
        <StarRoundedIcon key={index} className={classes.stars} />
      ))}
      <Typography variant="bodyLg" component="p" className={classes.review}>
        {comment}
      </Typography>
      <div className={classes.avatar}>
        <Avatar
          width={32}
          height={32}
          firstName={firstName}
          lastName={lastName}
        />
        <Typography>{firstName}</Typography>
      </div>
    </div>
  );
};

export default Card;
