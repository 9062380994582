export const teachersFeedback = [
  {
    teacherFirstName: 'Eva',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/eva.jpeg',
    rating: 4.86,
    subjectIntlId: 'spanish',
    feedbackIntlId: 'evaSpanishCharline',
    studentFirstName: 'Charline',
  },
  {
    teacherFirstName: 'Amine',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/amine.png',
    rating: 5,
    subjectIntlId: 'maths',
    feedbackIntlId: 'amineMathsYoann',
    studentFirstName: 'Yoann',
  },
  {
    teacherFirstName: 'Catherine',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/catherine.png',
    rating: 5,
    subjectIntlId: 'english',
    feedbackIntlId: 'catherineEnglishElina',
    studentFirstName: 'Elina',
  },
  {
    teacherFirstName: 'Jean',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/jean.png',
    rating: 5,
    subjectIntlId: 'maths',
    feedbackIntlId: 'jeanMathsCatherine',
    studentFirstName: 'Catherine',
  },
  {
    teacherFirstName: 'Léna',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/lena.png',
    rating: 5,
    subjectIntlId: 'french',
    feedbackIntlId: 'lenaFrenchAntonina',
    studentFirstName: 'Antonina',
  },
  {
    teacherFirstName: 'Dounia',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/dounia.png',
    rating: 5,
    subjectIntlId: 'maths',
    feedbackIntlId: 'douniaMathsLeonie',
    studentFirstName: 'Léonie',
  },
  {
    teacherFirstName: 'Philippine',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/philippine.png',
    rating: 5,
    subjectIntlId: 'spanish',
    feedbackIntlId: 'philippineSpanishAntoineMarie',
    studentFirstName: 'Antoine-Marie',
  },
  {
    teacherFirstName: 'Jean',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/jean-sebastien.png',
    rating: 5,
    subjectIntlId: 'maths',
    feedbackIntlId: 'jeanMathsLucas',
    studentFirstName: 'Lucas',
  },
  {
    teacherFirstName: 'Clément',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/clement.png',
    rating: 5,
    subjectIntlId: 'geopolitics',
    feedbackIntlId: 'clementGeopoliticsPierre',
    studentFirstName: 'Pierre',
  },
  {
    teacherFirstName: 'Raphael',
    photo:
      'https://sherpas-prod.s3.eu-west-3.amazonaws.com/landing/certified-teachers/raphael.png',
    rating: 5,
    subjectIntlId: 'french',
    feedbackIntlId: 'raphaelFrenchDelphine',
    studentFirstName: 'Delphine',
  },
];
