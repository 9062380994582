import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: rem(20),
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  text: {
    textAlign: 'center',
    [breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}));
