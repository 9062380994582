import { useEffect } from 'react';

import useIsElementOnScreen, {
  UseIsElementOnScreenProps,
} from '@/hooks/animation/useIsElementOnScreen';

export const CSS_ELEMENT_TOP = '--element-top';

type Props = {
  elementMargins?: UseIsElementOnScreenProps['elementMargins'];
};

const useAddContainerTopValueOnCssVar = ({ elementMargins }: Props) => {
  const { ref, isElementOnScreen, elementTop } = useIsElementOnScreen({
    elementMargins,
  });

  useEffect(() => {
    if (isElementOnScreen) {
      ref.current.style.setProperty(CSS_ELEMENT_TOP, `${elementTop}px`);
    }
  }, [ref, elementTop, isElementOnScreen]);

  return { ref };
};

export default useAddContainerTopValueOnCssVar;
