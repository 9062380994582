import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import { TagProps, TagStyle } from '@/Molecules/Tag/Tag.types';

const getBorderRadius = (size: TagProps['size']) =>
  size === 'small' ? rem(4) : rem(8);

export default makeStyles<TagStyle>()(
  (_theme, { backgroundColor, color, isRounded, size }) => ({
    root: {
      display: 'flex',
      padding: size === 'small' ? `0 ${rem(4)}` : `${rem(4)} ${rem(8)}`,
      alignItems: 'center',
      width: 'max-content',
      backgroundColor,
      borderRadius: isRounded ? rem(100) : getBorderRadius(size),
      gap: rem(6),
    },
    icon: {
      fontSize: 14,
      marginRight: rem(6),
      color,
    },
    typography: {
      color,
    },
  })
);
