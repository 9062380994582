const studentReviews = [
  {
    id: 'review1',
    firstName: 'Claire',
    lastName: 'R',
    date: '23/08/2023',
    comment:
      'Une très bonne plateforme de mise en relation avec les élèves et Les Sherpas, on trouve pleins de matières différentes, la plateforme est intuitive et on est très bien accompagnés. je recommande !',
    link: 'https://g.co/kgs/uNHJmC',
  },
  {
    id: 'review2',
    firstName: 'Baptiste',
    lastName: 'T',
    date: '20/08/2023',
    comment: `Excellente plateforme !
  Les professeurs répondent rapidement, et on y trouve des cours de qualité à des tarifs abordables. De plus, le site est bien conçu et clair.
  Il y a un très bon suivi de nos progrès : les membres de l'équipe de la plateforme prennent fréquemment de nos nouvelles et se montrent très disponibles. Cela s'avère donc pratique en cas de questions ou de demandes.
  Personnellement, lors de ma préparation aux concours, j'ai eu besoin de cours de français tout au long de l'année, et j'ai le sentiment d'avoir énormément progressé.
  Je recommande !`,
    link: 'https://g.co/kgs/3jCb1x',
  },
  {
    id: 'review3',
    firstName: 'Corine',
    lastName: 'O',
    date: '01/07/2023',
    comment: `Une très bonne plate-forme je ne peux que laisser un avis positif, l'interface est intuitive et conviviale et permet aux parents de naviguer facilement et de trouver des tuteurs qui conviennent. Le suivi est organisé et le service à la clientèle exceptionnel. Tout au long de mes interactions avec eux j'ai trouvé le personnel incroyablement compréhensif et sincèrement intéressé par les besoins de mon enfant. Mercredi Les Sherpas.`,
    link: 'https://g.co/kgs/9LTp9Y',
  },
  {
    id: 'review4',
    firstName: 'Kahina',
    lastName: 'K',
    date: '01/07/2023',
    comment: `La plateforme Sherpas est très efficace pour mettre en lien étudiants / enseignants. Les outils de visio sont performants et le suivi réalisé par l'équipe est très rigoureux.`,
    link: 'https://g.co/kgs/k2p79t',
  },
  {
    id: 'review5',
    firstName: 'Catherine',
    lastName: 'M',
    date: '01/07/2023',
    comment: `Je recommande tout à fait la plateforme des Sherpas. L'équipe est très réactive et les professeurs sont sélectionnés pour leur qualité.`,
    link: 'https://g.co/kgs/ihAZhJ',
  },
  {
    id: 'review6',
    firstName: 'Laureline',
    lastName: 'M',
    date: '01/07/2023',
    comment: `Des profs super.
  Une équipe pédagogique disponible et à l écoute. Des cours modulables en fonction des besoins.
  Ma fille a progressé pour terminer le troisième trimestre avec une très bonne moyenne dans la.matière concernée. Je conseille vivement les sherpa.`,
    link: 'https://g.co/kgs/D8NfTh',
  },
  {
    id: 'review7',
    firstName: 'Clotilde',
    lastName: 'A',
    date: '01/06/2023',
    comment: `Très satisfaite des sherpas ! Ils sont très réactifs et j'ai très rapidement trouvé les profs qui me convenaient. J'ai progressé très vite et ai donc pu ensuite consacrer les cours à de l'approfondissement. Je ne peux que recommander, super expérience.`,
    link: 'https://g.co/kgs/UqoW4k',
  },
  {
    id: 'review8',
    firstName: 'Cristale',
    lastName: 'D',
    date: '01/05/2023',
    comment: `Très bonne expérience, les cours de maths ont été très pertinent, je suis très contente de mon suivi je recommande vivement !! Grâce à vous j’ai beaucoup progressé en maths!`,
    link: 'https://g.co/kgs/dspqup',
  },
  {
    id: 'review9',
    firstName: 'Lidia',
    lastName: 'M',
    date: '01/04/2023',
    comment: `J’ai pris des cours particuliers pour me préparer au Bac et cela m’a beaucoup aidé.
      Je vous conseille ce site :)`,
    link: 'https://g.co/kgs/k7oNga',
  },
  {
    id: 'review10',
    firstName: 'Raphaëlle',
    lastName: 'R',
    date: '01/05/2023',
    comment: `Incroyable équipe qui est toujours présente aux côtés des élèves et qui met en relation les élèves avec des profs ultra compétents ! Merci pour tout`,
    link: 'https://g.co/kgs/w12z2j',
  },
];

export default studentReviews;
