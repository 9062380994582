import { FC, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AboutTeacher from '@/components/LandingMozza/common/CertifiedTeachers/AboutTeacher/AboutTeacher';
import { teachersFeedback } from '@/components/LandingMozza/common/CertifiedTeachers/constants';
import TeacherCard from '@/components/LandingMozza/common/CertifiedTeachers/TeacherCard/TeacherCard';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import TypographyUnderlined from '@/components/LandingMozza/LandingMozza/common/TypographyUnderlined/TypographyUnderlined';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import Button from '@/designSystem/Button/Button';
import useAddContainerTopValueOnCssVar from '@/hooks/animation/useAddContainerTopValueOnCssVar';

import Logos from './Logos/Logos';
import { feedbacks, messages, subjects } from './messages';

import useStyles from './styles';

type Props = {
  isMobile?: boolean;
  onGoToSearchPage: () => void;
};

const CertifiedTeachers: FC<Props> = ({ onGoToSearchPage, isMobile }) => {
  const { formatMessage } = useIntl();
  const { ref } = useAddContainerTopValueOnCssVar({
    elementMargins: { top: 20, bottom: 20 },
  });

  const { classes } = useStyles();

  return (
    <>
      <div className={classes.root} ref={ref}>
        <div className={classes.container}>
          <SectionTitle className={classes.title}>
            <FormattedMessage
              {...messages.title}
              values={{
                enter: EnterChunk,
                underlined: (chunks) => (
                  <TypographyUnderlined>
                    <b>{chunks}</b>
                  </TypographyUnderlined>
                ),
              }}
            />
          </SectionTitle>
          <AboutTeacher isMobile={isMobile} />
          <div>
            <Button
              variant="primary"
              size="large"
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                onGoToSearchPage();
              }}
              isFullWidth={isMobile}
              label={formatMessage(messages.btnLabel)}
            />
          </div>
        </div>
        <div className={classes.reviews}>
          <div className={classes.reviewContainer}>
            {teachersFeedback.map(
              ({
                teacherFirstName,
                photo,
                rating,
                subjectIntlId,
                feedbackIntlId,
                studentFirstName,
              }) => (
                <TeacherCard
                  key={feedbackIntlId}
                  teacherFirstName={teacherFirstName}
                  teacherLastName={teacherFirstName}
                  studentFirstName={studentFirstName}
                  subjectName={formatMessage(subjects[subjectIntlId])}
                  rating={rating}
                  feedback={formatMessage(feedbacks[feedbackIntlId])}
                  teacherPictureUrl={photo}
                  className={classes.reviewItem}
                />
              )
            )}
          </div>
        </div>
      </div>
      <Logos className={classes.logos} />
    </>
  );
};

export default CertifiedTeachers;
