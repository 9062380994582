import { defineMessages } from 'react-intl';

export default defineMessages({
  agency: {
    id: 'landing.mozza.pricing.section.agency.header',
    defaultMessage: 'AVEC<secondLine>UNE AGENCE CLASSIQUE*</secondLine>',
  },
  sherpas: {
    id: 'landing.mozza.pricing.section.sherpas.header',
    defaultMessage: 'AVEC<secondLine><logo></logo></secondLine>',
  },
});
