import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, border }) => ({
  container: {
    border: border.main,
    borderRadius: rem(8),
    minWidth: rem(310),
    width: rem(310),
    height: rem(248),
    padding: rem(24),
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    justifyContent: 'space-between',
  },
  cardHeader: {
    display: 'flex',
    gap: rem(8),
    alignItems: 'center',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: {
    display: 'flex',
  },
  noWrap: {
    whiteSpace: 'initial',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  studentName: {
    color: palette.neutral[500],
    fontStyle: 'italic',
  },
  headerAndFeedback: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(20),
  },
}));
