import { ReactElement, ReactNode } from 'react';

import useStyles from './styles';

type TableProps = {
  children: ReactNode;
  withBorder: boolean;
};

const Table = ({ children, withBorder }: TableProps): ReactElement => {
  const { classes, cx } = useStyles();

  return (
    <table className={cx(classes.container, withBorder && classes.withBorder)}>
      {children}
    </table>
  );
};

export default Table;
