import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import messages from './messages';

const Legend: FC = () => {
  const textVariant = 'bodyLg';

  return (
    <div>
      <Typography variant={textVariant} component="p">
        <FormattedMessage {...messages.firstStar} />
      </Typography>
      <Typography variant={textVariant} component="p">
        <FormattedMessage {...messages.secondStar} />
      </Typography>
    </div>
  );
};

export default Legend;
