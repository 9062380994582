import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
} from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

const message = defineMessage({
  id: 'design.system.button.close',
  defaultMessage: 'Fermer',
});

export type CloseButtonProps = {
  onClick: MouseEventHandler;
  classIcon?: string;
};

const useStyles = makeStyles()(() => ({
  root: {
    padding: 0,
    marginLeft: rem(12),
  },
  icon: {
    width: rem(16),
    height: rem(16),
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: rem(1),
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: rem(1),
  },
}));

const CloseButton: FC<
  PropsWithChildren<CloseButtonProps & HTMLAttributes<HTMLDivElement>>
> = ({ onClick, className, classIcon }) => {
  const { classes, cx } = useStyles();
  return (
    <IconButton
      className={className}
      classes={{ root: classes.root }}
      onClick={onClick}
      size="large"
    >
      <CloseRoundedIcon className={cx(classes.icon, classIcon)} />
      <span className={classes.visuallyHidden}>
        <FormattedMessage {...message} />
      </span>
    </IconButton>
  );
};

export default CloseButton;
