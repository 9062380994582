import { defineMessages } from 'react-intl';

export const aboutTeachers = defineMessages({
  about1: {
    id: 'landingMozza.certifiedTeachers.aboutTeachers.about1',
    defaultMessage: `Issus des meilleures filières`,
  },
  about2: {
    id: 'landingMozza.certifiedTeachers.aboutTeachers.about2',
    defaultMessage: `Identité et diplômes vérifiés`,
  },
  about3: {
    id: 'landingMozza.certifiedTeachers.aboutTeachers.about3',
    defaultMessage: `Formés par notre équipe`,
  },
  about4: {
    id: 'landingMozza.certifiedTeachers.aboutTeachers.about4',
    defaultMessage: `Au meilleur rapport qualité-prix`,
  },
});
