import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import ContentCellDesktop from '@/components/LandingMozza/common/PricingSection/PricingTable/ContentCell/ContentCellDesktop';
import ContentCellMobile from '@/components/LandingMozza/common/PricingSection/PricingTable/ContentCell/ContentCellMobile';
import HeaderCell from '@/components/LandingMozza/common/PricingSection/PricingTable/HeaderCell/HeaderCell';
import { DisplayedColumn } from '@/components/LandingMozza/common/PricingSection/types';
import Button from '@/designSystem/Button/Button';

import Cell from './Cell/Cell';
import Table from './Table/Table';
import messages from './messages';

import useStyles from './styles';

type Props = {
  displayedColumn: DisplayedColumn;
  isMobile?: boolean;
  handleRedirection?: () => void;
};

const NB_ROWS = 7;

const PricingTable: FC<Props> = ({
  displayedColumn,
  isMobile = true,
  handleRedirection,
}): ReactElement => {
  const { classes, cx } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <div
        className={cx(
          classes.borderRadius,
          displayedColumn !== 'agency' && classes.tableContainer
        )}
      >
        <Table withBorder={displayedColumn === 'sherpas'}>
          <thead>
            <tr>
              {isMobile ? (
                <HeaderCell displayedColumn={displayedColumn} />
              ) : (
                <>
                  <Cell isHeader />
                  <HeaderCell displayedColumn="agency" />
                  <HeaderCell displayedColumn="sherpas" />
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {new Array(NB_ROWS).fill('').map((_, index) => (
              <tr key={index}>
                {isMobile ? (
                  <ContentCellMobile
                    displayedColumn={displayedColumn}
                    rowNumber={index + 1}
                    isLastRow={index === NB_ROWS - 1}
                  />
                ) : (
                  <>
                    <ContentCellDesktop
                      column="advantage"
                      rowNumber={index + 1}
                    />
                    <ContentCellDesktop column="agency" rowNumber={index + 1} />
                    <ContentCellDesktop
                      column="sherpas"
                      rowNumber={index + 1}
                    />
                  </>
                )}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              {!isMobile && (
                <>
                  <Cell isFooter />
                  <Cell isFooter />
                </>
              )}
              {!(isMobile && displayedColumn !== 'sherpas') && (
                <Cell withBackground isFooter isCenter>
                  <Button
                    variant="primary"
                    size="large"
                    isFullWidth
                    onClick={handleRedirection}
                    label={formatMessage(messages.button)}
                  />
                </Cell>
              )}
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

export default PricingTable;
