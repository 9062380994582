import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    padding: 0,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: rem(8),
    marginBottom: rem(12),
  },
  label: {
    fontWeight: 500,
  },
}));
