import { defineMessages } from 'react-intl';

export default defineMessages({
  advantage1: {
    id: 'landing.mozza.pricing.section.advantage.one',
    defaultMessage: 'Professeur certifié, au juste prix',
  },
  agency1: {
    id: 'landing.mozza.pricing.section.agency.one',
    defaultMessage: '<dashIcon></dashIcon>',
  },
  sherpas1: {
    id: 'landing.mozza.pricing.section.sherpas.one',
    defaultMessage: '<checkIcon></checkIcon>',
  },

  advantage2: {
    id: 'landing.mozza.pricing.section.advantage.three',
    defaultMessage: `Cours d'essai offert`,
  },
  agency2: {
    id: 'landing.mozza.pricing.section.agency.three',
    defaultMessage: '<dashIcon></dashIcon>',
  },
  sherpas2: {
    id: 'landing.mozza.pricing.section.sherpas.three',
    defaultMessage: '<checkIcon></checkIcon>',
  },

  advantage3: {
    id: 'landing.mozza.pricing.section.advantage.four',
    defaultMessage: `Changement de professeur sans frais`,
  },
  agency3: {
    id: 'landing.mozza.pricing.section.agency.four',
    defaultMessage: '<dashIcon></dashIcon>',
  },
  sherpas3: {
    id: 'landing.mozza.pricing.section.sherpas.four',
    defaultMessage: '<checkIcon></checkIcon>',
  },

  advantage4: {
    id: 'landing.mozza.pricing.section.advantage.five',
    defaultMessage: `Apprentissage avec la méthode Sherpas<sup>®</sup>`,
  },
  agency4: {
    id: 'landing.mozza.pricing.section.agency.five',
    defaultMessage: '<dashIcon></dashIcon>',
  },
  sherpas4: {
    id: 'landing.mozza.pricing.section.sherpas.five',
    defaultMessage: '<checkIcon></checkIcon>',
  },

  advantage5: {
    id: 'landing.mozza.pricing.section.advantage.six',
    defaultMessage: `<primary>Frais pédagogiques</primary>`,
  },
  agency5: {
    id: 'landing.mozza.pricing.section.agency.six',
    defaultMessage:
      '<primary>80 €</primary><enter></enter><secondary>non remboursables</secondary>',
  },
  sherpas5: {
    id: 'landing.mozza.pricing.section.sherpas.six',
    defaultMessage:
      '<primary>19,90 €</primary><enter></enter><secondary>satisfait ou remboursé</secondary>',
  },

  advantage6: {
    id: 'landing.mozza.pricing.section.advantage.seven',
    defaultMessage: `<primary>Tarif moyen d'un cours</primary>`,
  },
  agency6: {
    id: 'landing.mozza.pricing.section.agency.seven',
    defaultMessage: '<primary>à partir de 40 €/h</primary>',
  },
  sherpas6: {
    id: 'landing.mozza.pricing.section.sherpas.seven',
    defaultMessage:
      '<primary>entre 15 et 89 €/h</primary><enter></enter><secondary>selon votre budget</secondary>',
  },

  advantage7: {
    id: 'landing.mozza.pricing.section.advantage.height',
    defaultMessage: `<primary>Commission sur le prix des cours</primary>`,
  },
  agency7: {
    id: 'landing.mozza.pricing.section.agency.height',
    defaultMessage:
      '<primary>200 %**</primary><enter></enter><secondary>en moyenne</secondary>',
  },
  sherpas7: {
    id: 'landing.mozza.pricing.section.sherpas.height',
    defaultMessage:
      '<primary>35 %</primary><enter></enter><secondary>en moyenne</secondary>',
  },
});
