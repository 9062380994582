import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import CustomerReviews from '@/components/LandingMozza/common/CustomerReviews/CustomerReviews';
import RatingGoogle from '@/components/LandingMozza/common/RatingGoogle/RatingGoogle';
import studentReviews from '@/components/LandingMozza/common/Reviews/mockedReviews';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import Button from '@/designSystem/Button/Button';
import Enter from '@/generic/Enter/Enter';

import messages from './messages';

import useStyles from './styles';

type Props = {
  handleRedirection: () => void;
};

const Reviews: FC<Props> = ({ handleRedirection }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <ArticleHeader>
        <SectionTitle>
          <FormattedMessage
            {...messages.title}
            values={{ enter: (chunk) => <Enter>{chunk}</Enter> }}
          />
        </SectionTitle>
        <RatingGoogle gtmEvent="gmb_landingReviews" />
      </ArticleHeader>
      <CustomerReviews reviews={studentReviews} />
      <div className={classes.button}>
        <Button
          variant="primary"
          size="large"
          isFullWidth
          onClick={handleRedirection}
          label={formatMessage(messages.button)}
        />
      </div>
    </div>
  );
};

export default Reviews;
