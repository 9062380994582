import { FC, ReactElement, ReactNode } from 'react';

import useStyles from './styles';

type CellProps = {
  isHeader?: boolean;
  isFooter?: boolean;
  isCenter?: boolean;
  withBackground?: boolean;
  children?: ReactNode;
  className?: string;
  isLastRow?: boolean;
};

const Cell: FC<CellProps> = ({
  isHeader = false,
  isFooter = false,
  isCenter = false,
  withBackground = false,
  isLastRow = false,
  className,
  children,
}: CellProps): ReactElement => {
  const { classes, cx } = useStyles();
  return (
    <td
      className={cx({
        [classes.container]: true,
        [classes.isCenter]: isCenter,
        [classes.isFooter]: isFooter,
        [classes.isHeader]: isHeader,
        [classes.withBackground]: withBackground,
        [classes.borderBottom]: !isLastRow,
        [className]: true,
      })}
    >
      {children}
    </td>
  );
};

export default Cell;
