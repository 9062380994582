import { createContext, useContext } from 'react';

import { TagContextType } from '@/Molecules/Tag/Tag.types';

const TagContext = createContext<TagContextType>({});

export const TagProvider = TagContext.Provider;
export const useTagContext = () => {
  const context = useContext(TagContext);
  if (!context) {
    throw new Error(
      'Le contexte du composant Tag ne peut être utilisé qu avec les sous composant de tag'
    );
  }
  return context;
};
